/* eslint camelcase: 0 */
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// @ts-ignore
import { Media } from 'gatsby-plugin-fresnel';

import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import InputGroup from '../components/ui/InputGroup';
import Label from '../components/ui/Label';
import Header from '../components/ui/Header';
// @ts-ignore
import { ReactComponent as TripleChevronRight } from '../assets/vectors/TripleChevronRight.svg';
// @ts-ignore
import { ReactComponent as HugloPrimary } from '../assets/vectors/Huglo_Primary.svg';
// @ts-ignore
import { ReactComponent as SavingsIcon } from '../assets/vectors/SavingsIcon.svg';
// @ts-ignore
import { ReactComponent as SavedPreferencesIcon } from '../assets/vectors/SavedPreferencesIcon.svg';
// @ts-ignore
import { ReactComponent as TenPercentIcon } from '../assets/vectors/TenPercentIcon.svg';
import PerkItem from '../components/general/PerkItem';
import {
  getBillPresenceType,
  getBusinessId,
  getCurrentPlanLS,
  getCurrentRetailer,
  getHasSolarSystem,
  getHouseholdSize,
  getLocalityLS,
  getResidenceId,
  getSelectedDistributorLS,
  getSelectedRecommendedPlan,
  setResultsToken,
  yesNoToBool,
} from '../utils/localStorage/localStorageFunctions';
import { GetRecommendation_resultSack_recommendationList } from '../apollo/__generated__/GetRecommendation';
import { getRetailerLogoUrl } from '../components/general/RetailersLogos';
import { CREATE_MEMBER } from '../apollo/queries';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import ContinuePopup from '../components/general/ContinuePopup';
import {
  CreateMember,
  CreateMemberVariables,
} from '../apollo/__generated__/CreateMember';
import usePreloadPageData from '../hooks/usePreloadPageData';
import BackButton from '../components/ui/BackButton';
import SEOLayout from '../components/layouts/SEOLayout';

const style = require('./AlmostThere.module.scss');

const ContinueSavingSchema = Yup.object().shape({
  email: Yup.string().email('Please check the email entered'),
});

const AlmostThere = () => {
  const [createUser, { data: createUserData, loading: createUserLoading }] =
    useMutation<CreateMember, CreateMemberVariables>(CREATE_MEMBER);

  const [recommendedPlan] =
    useState<GetRecommendation_resultSack_recommendationList | null>(() =>
      getSelectedRecommendedPlan()
    );

  const retailerName = recommendedPlan?.plan?.retailer?.orgName ?? '';
  const retailerWebsiteUrl = recommendedPlan?.plan?.retailer?.websiteUrl ?? '';

  const initialFormValues = {
    email: '',
  };

  const handleCreateUser = (values: typeof initialFormValues) => {
    const locality = getLocalityLS();
    const distributor = getSelectedDistributorLS();
    const householdSize = getHouseholdSize();
    const billPresenceType = getBillPresenceType();
    const hasSolarSystem = getHasSolarSystem();
    const retailer = getCurrentRetailer();
    const currentPlan = getCurrentPlanLS();

    const additionals = {
      postcode: locality?.postcode?.postcode,
      locality: locality?.name,
      distributor: distributor?.name,
      household: householdSize,
      bill_info:
        billPresenceType === 'pdfBill' || billPresenceType === 'paperBill',
      solar: yesNoToBool(hasSolarSystem),
      // smart_meter: energyUsageLs.hasSmartMeter,
      // green_power_preference: preferencesLs.greenPowerRequired,
      current_retailer_name: retailer?.orgName,
      current_plan_name: currentPlan?.name,
      current_plan_ID: currentPlan?.id,
      results_url: '',
    };

    const propertyId = getResidenceId();
    const businessId = getBusinessId();
    if (!(propertyId || businessId)) return;

    createUser({
      variables: {
        fname: '',
        lname: '',
        email: values.email,
        // @ts-ignore
        propertyId: businessId ?? propertyId,
        additionalInfo: JSON.stringify(additionals),
        selectedPlanId: recommendedPlan?.plan?.id ?? '',
        selectedRetailerId: recommendedPlan?.plan?.retailer?.id ?? '',
      },
    });
  };

  const navigateToRetailer = (retailerWebsite: string) => {
    let retailerWebsiteWithProtocol = retailerWebsite;
    // Add protocol if not present
    if (
      !(
        retailerWebsite.startsWith('http') ||
        retailerWebsite.startsWith('https') ||
        retailerWebsite.startsWith('ftp') ||
        retailerWebsite.startsWith('//')
      )
    ) {
      retailerWebsiteWithProtocol = `//${retailerWebsite}`;
    }

    setTimeout(() => {
      window.open(retailerWebsiteWithProtocol, '_blank');
    }, 1000);

    navigateAndSetActiveStep(StepUrl.URL_PLAN_INFO, {
      state: { showPopup: true },
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!createUserData || createUserLoading) return;

    if (!createUserData?.createMember?.member?.id) return;

    if (createUserData?.createMember?.member?.resultsToken)
      setResultsToken(createUserData?.createMember?.member?.resultsToken);

    navigateToRetailer(retailerWebsiteUrl);
  }, [createUserData, createUserLoading]);

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_PLAN_INFO);
  };

  usePreloadPageData(StepUrl.URL_PLAN_INFO);

  return (
    <SEOLayout>
      <ContinuePopup pageUrl={StepUrl.URL_ALMOST_THERE} />
      <div className="container-fluid" style={{ maxWidth: '1600px' }}>
        <Header />
        <main className={style.main}>
          <Media lessThan="tablet">
            <h3 className={style.topHeading}>Thank you, almost there...</h3>
          </Media>
          <Media greaterThanOrEqual="tablet">
            <h1 className={style.topHeading}>Thank you, almost there...</h1>
          </Media>
          <Formik
            initialValues={initialFormValues}
            onSubmit={handleCreateUser}
            validationSchema={ContinueSavingSchema}
            validateOnChange={false}
          >
            {(props) => (
              <Form>
                <div className={style.chevronSection}>
                  <HugloPrimary
                    width={150}
                    style={{ marginTop: '14px' }}
                    className={style.hugloLogo}
                  />
                  <TripleChevronRight
                    className={style.chevronIcon}
                    width={60}
                  />
                  <img
                    src={getRetailerLogoUrl(retailerName)}
                    alt={retailerName}
                    width={280}
                    height={80}
                    className={style.retailerLogo}
                    onClick={() => {
                      props.submitForm();
                    }}
                  />
                  <p className={style.hugloText}>
                    We’ve found you the best deal.
                  </p>
                  <div />
                  <p className={style.retailerText}>
                    Complete your sign-up with
                    <br />
                    {retailerName}
                  </p>
                </div>
                <div className={style.orangeSection}>
                  <div>
                    <Media lessThan="tablet">
                      <h3>Want to continue saving?</h3>
                    </Media>
                    <Media greaterThanOrEqual="tablet">
                      <h2>Want to continue saving?</h2>
                    </Media>
                    <p>
                      Sign up, and we’ll contact you again when it’s time to
                      save.
                    </p>
                  </div>
                  <InputGroup className={style.narrowMobileFullWidth}>
                    <Label htmlFor="email">Email address</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Add your email"
                      className={style.narrowMobileFullWidth}
                    />
                  </InputGroup>
                  <div
                    className={classNames(
                      style.buttons,
                      style.narrowMobileFullWidth
                    )}
                  >
                    <Button type="submit" disabled={!props.values.email}>
                      Sign up & choose plan
                    </Button>
                    <Button type="submit" variant="tertiary">
                      No thanks, continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <BackButton
            text="Back to plan"
            className={style.backButton}
            onClick={handleBack}
          />
          <div className={style.perks}>
            <PerkItem
              title="10% off solar & more"
              text="Get discounts exclusively available to Huglo members."
              icon={<TenPercentIcon width={48} height={48} />}
            />
            <PerkItem
              title="Saved preferences"
              text="No need to keep entering your details, we’ll keep them safe."
              icon={<SavedPreferencesIcon width={48} height={48} />}
            />
            <PerkItem
              title="Ongoing savings tips"
              text="We’ll keep you updated so you never miss out on saving more."
              icon={<SavingsIcon width={48} height={48} />}
            />
          </div>
        </main>
      </div>
    </SEOLayout>
  );
};

export default AlmostThere;
