import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from '../../ui/Button';
import ButtonGroup from '../../ui/ButtonGroup';
import {
  getActiveStepUrlLS,
  setActiveStepUrlLS,
} from '../../../utils/localStorage/localStorageFunctions';
import { navigateAndSetActiveStep } from '../../../utils/utils';
import { StepUrl } from '../../../utils/enums';

const style = require('./ContinuePopup.module.scss');

type ContinuePopupProps = {
  className?: string;
  headingText?: string;
  paragraphText?: string;
  pageUrl: StepUrl;
  [otherProps: string]: any;
  // eslint-disable-next-line react/boolean-prop-naming
  ignoreActiveStep?: boolean;
};

const ContinuePopup = (props: ContinuePopupProps) => {
  const {
    className,
    headingText,
    paragraphText,
    pageUrl,
    ignoreActiveStep,
    ...rest
  } = props;

  const [isContinuePopupVisible, setIsContinuePopupVisible] = useState(false);

  useEffect(() => {
    if (ignoreActiveStep) return;

    const activeStepUrlLS = getActiveStepUrlLS();
    // First visit
    if (!activeStepUrlLS) {
      if (
        !(
          pageUrl === StepUrl.URL_POSTCODE_HOUSEHOLD ||
          pageUrl === StepUrl.URL_POSTCODE_BUSINESS
        )
      ) {
        // First visit to the wrong page
        localStorage.clear();
        navigateAndSetActiveStep(StepUrl.URL_POSTCODE_HOUSEHOLD);
        return;
      } else {
        // First visit to the correct page
        setActiveStepUrlLS(pageUrl);
        return;
      }
    }

    if (activeStepUrlLS !== pageUrl) {
      // Second visit to the wrong page

      if (
        (activeStepUrlLS === StepUrl.URL_POSTCODE_HOUSEHOLD &&
          pageUrl === StepUrl.URL_POSTCODE_BUSINESS) ||
        (activeStepUrlLS === StepUrl.URL_POSTCODE_BUSINESS &&
          pageUrl === StepUrl.URL_POSTCODE_HOUSEHOLD)
      ) {
        // Treat postcode pages as one
        return;
      }

      if (
        activeStepUrlLS === StepUrl.URL_RESULTS &&
        pageUrl === StepUrl.URL_RETAILER_CHOICE
      ) {
        setIsContinuePopupVisible(true);
      }
    }
  }, [pageUrl, ignoreActiveStep]);

  const handleContinue = () => {
    const activeStepUrlLS = getActiveStepUrlLS();
    if (activeStepUrlLS) {
      navigateAndSetActiveStep(activeStepUrlLS);
      setIsContinuePopupVisible(false);
    }
  };

  const handleRestart = () => {
    localStorage.clear();
    navigateAndSetActiveStep(StepUrl.URL_POSTCODE_HOUSEHOLD);
    setIsContinuePopupVisible(false);
    // TODO: clear input on postcode page
  };

  return (
    <div
      {...rest}
      className={classNames(style.restartPopupWrapper, className)}
      style={{ display: isContinuePopupVisible ? 'flex' : 'none' }}
    >
      <div className={style.restartPopup}>
        <h2>{headingText}</h2>
        <p>{paragraphText}</p>
        <ButtonGroup className={style.buttonGroup}>
          <Button type="button" variant="tertiary" onClick={handleRestart}>
            No, thanks
          </Button>
          <Button type="button" onClick={handleContinue}>
            Yes, please
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

ContinuePopup.defaultProps = {
  className: '',
  headingText: 'Welcome back!',
  ignoreActiveStep: false,
  paragraphText: 'Would you like to pick up from where you left of?',
};

export default ContinuePopup;
