// extracted by mini-css-extract-plugin
export var main = "AlmostThere-module--main--ftGZl";
export var narrowMobileFullWidth = "AlmostThere-module--narrowMobileFullWidth--121Gb";
export var topHeading = "AlmostThere-module--topHeading--1Jpv1";
export var chevronSection = "AlmostThere-module--chevronSection--248xS";
export var chevronIcon = "AlmostThere-module--chevronIcon--2swQE";
export var hugloLogo = "AlmostThere-module--hugloLogo--3OLs1";
export var hugloText = "AlmostThere-module--hugloText--tBN1m";
export var retailerLogo = "AlmostThere-module--retailerLogo--1vyng";
export var retailerText = "AlmostThere-module--retailerText--2jClI";
export var orangeSection = "AlmostThere-module--orangeSection--Kuc6C";
export var buttons = "AlmostThere-module--buttons--ZNKxN";
export var backButton = "AlmostThere-module--backButton--CVjK_";
export var perks = "AlmostThere-module--perks--3egvp";